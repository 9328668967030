import {
  addHours,
  format,
  isDate,
  isToday,
  isYesterday,
  parseISO,
  startOfWeek
} from "date-fns"

import { zonedTimeToUtc } from "date-fns-tz"

import {
  ISSUE_STATUS_ARCHIVED,
  ISSUE_TABS_CLOSE_ALL,
  ISSUE_TABS_CLOSE_OTHERS,
  ISSUE_TITLE_MAX_LENGTH,
  ENG_MAX_ISSUE_TABS_LIMIT,
  OPEN_ISSUES_TAB_PERSIST_KEY,
  SCAN_STATUS
} from "../constants"

export const getFormatedDate = msgDate => {
  const timeFormat = "hh:mm a"
  const yesterday = isYesterday(msgDate)
  const today = isToday(msgDate)
  const formattedTime = format(msgDate, timeFormat)

  if (yesterday) {
    return `Yesterday at ${formattedTime}`
  } else if (today) {
    return `Today at ${formattedTime}`
  }
  const formattedDate = format(msgDate, "MMMM do, yyyy")
  return `${formattedDate} at ${formattedTime}`
}

export const getUserDisplayValue = user => {
  const { userId = "", name = "", email = "" } = user
  if (userId) {
    let displayValue = userId
    if (name) {
      displayValue = name
    } else if (email) {
      displayValue = email
    }
    return displayValue
  }
}

export const getMessageDeletedText = (message, users) => {
  const attributes = message.attributes || {}
  const deletedAt = attributes.deletedAt
  const deletedBy = attributes.deletedBy
  const user = users.find(u => u.userId === deletedBy)
  let msg = "Deleted"
  if (attributes.scanStatus === SCAN_STATUS.FOUND) {
    msg = "Threat detected and deleted"
  }
  if (user) {
    msg = `${msg} by ${user.name}`
  }
  if (deletedAt) {
    const formatedDt = getFormatedDate(new Date(deletedAt))
    msg = `${msg} ${formatedDt}`
  }
  msg = `${msg}.`
  return msg
}

export const getAuthorInitials = author => {
  const { email = "", name = "" } = author
  if (author.isUnknown) {
    return "X"
  } else if (name) {
    return name.charAt(0)
  } else if (email) {
    return email.charAt(0)
  }
}

export const JUMP_LINK_TYPES = {
  MOST_RECENT: "MOST_RECENT",
  LAST_WEEK: "LAST_WEEK",
  LAST_MONTH: "LAST_MONTH",
  START: "START",
  TO_DATE: "TO_DATE",
  PREVIOUS_DAY: "PREVIOUS_DAY",
  NEXT_DAY: "NEXT_DAY"
}

export const hasUnreadMessagesInIssue = conversation => {
  const { issue, issueStatus } = conversation
  const { last_message_index, read_message_index } = issue

  if (issueStatus === ISSUE_STATUS_ARCHIVED) {
    return false
  }
  // The issue will be shown as bold when
  // 1. last message index is not yet updated on the database
  //    or there are no messages on an issue
  // 2. read_message_index is not available since the user has not
  //    read any messages on that issue
  // 3 . User has not read all the messages on the issue
  const hasUnreadMessages =
    last_message_index === undefined ||
    read_message_index === undefined ||
    read_message_index < last_message_index
  return hasUnreadMessages
}

export const getAuthorBgFillCls = author => {
  let fillCls = "bg-blue"
  if (author.isUnknown && author.isEngineer) {
    return "bg-red bg-opacity-25"
  }
  if (author.isUnknown && !author.isEngineer) {
    return "bg-gray-300"
  } else if (author.isEngineer) {
    return "bg-red"
  }

  return fillCls
}

export const formatIssueCreatedDate = created_at => {
  return format(new Date(created_at), "MMM, dd, yyyy, hh:mm a")
}

export const CHAT_SYSTEM_USER = "system"

export const persistUserTabs = tabKey => {
  const issueNumber = Number.parseInt(tabKey)

  let existingTabs = getPersistedUserTabs()

  if (
    Number.isInteger(issueNumber) &&
    existingTabs.indexOf(issueNumber) === -1
  ) {
    existingTabs = [issueNumber].concat(existingTabs) // add as first item
    const tabListWithLimitApplied = existingTabs.slice(
      0,
      ENG_MAX_ISSUE_TABS_LIMIT
    )
    const tabToRestore = [...new Set(tabListWithLimitApplied)]
    localStorage.setItem(
      OPEN_ISSUES_TAB_PERSIST_KEY,
      JSON.stringify(tabToRestore)
    )
  }
}

export const removePersistedUserTab = (tabKey, option) => {
  let restOpenTabs = []
  const issueNumber = Number.parseInt(tabKey)

  if (option === ISSUE_TABS_CLOSE_ALL) {
    restOpenTabs = []
    ChatMsgLocalStore.resetDrafts()
  } else if (option === ISSUE_TABS_CLOSE_OTHERS) {
    getPersistedUserTabs().forEach(pTabKey => {
      if (pTabKey !== issueNumber) {
        ChatMsgLocalStore.removeDraft(pTabKey)
      }
    })
    restOpenTabs = [issueNumber]
  } else {
    getPersistedUserTabs().forEach(pTabKey => {
      if (pTabKey === issueNumber) {
        ChatMsgLocalStore.removeDraft(pTabKey)
      }
    })

    let existingTabs = getPersistedUserTabs()
    restOpenTabs = existingTabs.filter(ti => ti !== issueNumber)
  }

  localStorage.setItem(
    OPEN_ISSUES_TAB_PERSIST_KEY,
    JSON.stringify(restOpenTabs)
  )
}

export const getPersistedUserTabs = () => {
  return (
    JSON.parse(localStorage.getItem(OPEN_ISSUES_TAB_PERSIST_KEY) || "[]") || []
  )
}

export const ChatMsgLocalStore = {
  keyPrefix: "reply_draft_",
  removeDraft: function (issueNumber) {
    localStorage.removeItem(`${this.keyPrefix}${issueNumber}`)
  },
  getDraft: function (issueNumber) {
    return localStorage.getItem(`${this.keyPrefix}${issueNumber}`)
  },
  saveDraft: function (issueNumber, msg) {
    //to sync across tabs , even if empty draft message just update it..
    return localStorage.setItem(`${this.keyPrefix}${issueNumber}`, msg)
  },
  resetDrafts: function () {
    Object.keys(localStorage).forEach(replyDraftKey => {
      if (replyDraftKey.startsWith(this.keyPrefix)) {
        localStorage.removeItem(replyDraftKey)
      }
    }, [])
  }
}

export const getMsgBodyWithForcedFormat = message => {
  const { force_format = false } = message.attributes || {}
  let msgContent = message.body
  if (force_format) {
    msgContent = `\`\`\`\n${msgContent}\n\`\`\``
  }
  return msgContent
}

export const isWeekend = () => {
  const dt = new Date()
  let isWeekend = false
  if (
    (dt.getDay() === 6 && dt.getUTCHours() >= 4) || // Saturday, after 4 UTC
    dt.getDay() === 0 || // Sunday
    (dt.getDay() === 1 && dt.getUTCHours() < 4) // Monday, before 4 UTC
  ) {
    isWeekend = true
  }
  return isWeekend
}

export const getUTCWeekStartTime = () => {
  const nowUtc = zonedTimeToUtc(new Date(), "UTC")

  // Get the start of the current week in UTC and add 4 hours
  const startOfWeekUtc = zonedTimeToUtc(
    startOfWeek(nowUtc, { weekStartsOn: 1 }),
    "UTC"
  ) // Monday UTC 04:00
  return addHours(startOfWeekUtc, 4)
}

export const getLimitedIssueTitle = (titleTxt = "") => {
  let withLimitTxt = titleTxt.substring(0, ISSUE_TITLE_MAX_LENGTH)

  return withLimitTxt
}

export const isDateBeforeUTCWeekend = timestamp => {
  const incomingTs = isDate(timestamp) ? timestamp : parseISO(timestamp)

  const day = incomingTs.getUTCDay() // 0 = Sunday, 6 = Saturday
  const hours = incomingTs.getUTCHours()

  // Check if the timestamp is between Saturday 04:00 UTC and Monday 04:00 UTC
  if (day === 6 && hours >= 4) {
    // It's Saturday after 04:00 UTC
    return false
  }

  if (day === 0 || (day === 1 && hours < 4)) {
    // It's Sunday or Monday before 04:00 UTC
    return false
  }

  // All other times are before the weekend
  return true
}
