import { SORT_ORDER } from "../constants"
import { userSearchComparator } from "../utils"
import format from "date-fns/format"
import differenceInDays from "date-fns/differenceInDays"
import { saveBlob } from "../utils"
import { ACCOUNT_TYPE_CUSTOM } from "../subscription/SubscriptionConstants"
import { sort } from "fast-sort"
import { differenceInMinutes } from "date-fns"

const { Parser } = require("json2csv")

export const accountRowFormatter = (accInfoEntry: any) => {
  const {
    subscription_start_date: subscriptionStartDate,
    subscription_end_date: subscriptionEndDate,
    subscription_cancel_at: subscriptionCancelAt,
    contract_capacity,
    account_type,
    subscribed_products = [],
    ...restKeys
  } = accInfoEntry
  const [subscriptionPlan = {}] = subscribed_products

  const {
    quantity: planCapacity = "",
    minQuantity,
    quantityUpdatedAt: lastUsageUpdateDate,
    quantity
  } = subscriptionPlan

  const capacityNum = parseInt(planCapacity)

  const { type } = account_type
  const billedSubscriptionCapacity = Math.max(minQuantity, quantity)

  let planContractCapacity =
    type === ACCOUNT_TYPE_CUSTOM
      ? contract_capacity
      : billedSubscriptionCapacity

  return {
    ...restKeys,
    subscriptionStartDate: subscriptionStartDate,
    subscriptionEndDate: subscriptionEndDate || subscriptionCancelAt,
    lastUsageUpdateDate: lastUsageUpdateDate,
    planVersion: subscriptionPlan.planVersion,
    planSlaHours: subscriptionPlan.planSlaHours,
    planSlaCoverage: subscriptionPlan.planSlaCoverage,
    account_type: account_type,
    billedSubscriptionCapacity: billedSubscriptionCapacity,
    contract_capacity: planContractCapacity,
    reported_capacity: capacityNum
  }
}
export const formatAccResponse = (list: any[]) => {
  const formattedAccountList = list.map(accountRowFormatter)

  return formattedAccountList
}

export const getActiveAccounts = (accountList: any = []) => {
  return accountList.filter((accItem: any) => {
    return !accItem.is_disabled
  })
}

export const getInactiveAccounts = (accountList: any = []) => {
  return accountList.filter((accItem: any) => {
    return accItem.is_disabled
  })
}

export const getCustomersIFollow = (
  accountList: any = [],
  followingAccounts: number[] = []
) => {
  return accountList.filter((accItem: any) => {
    return (followingAccounts || []).includes(accItem.account_id)
  })
}

export const filterByPlans = (accountList: any = [], plans: string[]) => {
  return accountList.filter((accItem: any) => {
    const serviceType = (accItem?.account_type?.serviceType || "").toLowerCase()
    return plans.includes(serviceType)
  })
}

export const getSortedAccountList = (
  accountsToSort: any,
  columnName: string,
  sortOrder: string
) => {
  let sortedAccList = []
  const isDescending = sortOrder === SORT_ORDER.DESC

  let sortFn = (c: any) => (c[columnName] || "").toLowerCase()

  const dateColumns = [
    "subscriptionStartDate",
    "subscriptionEndDate",
    "lastUsageUpdateDate",
    "last_message_at"
  ]

  const numberColumns = ["reported_capacity", "contract_capacity"]

  if (dateColumns.includes(columnName)) {
    sortFn = (c: any) => new Date(c[columnName])
  }
  if (numberColumns.includes(columnName)) {
    //Numerical Sort
    sortFn = (c: any) =>
      Number.parseFloat(c[columnName]) ||
      (isDescending ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER)
  }
  //nested properties
  if (columnName === "account_type.serviceType") {
    sortFn = (c: any) => c.account_type?.serviceType
  } else if (columnName === "account_type.type") {
    sortFn = (c: any) => c.account_type?.type
  }

  if (isDescending) {
    sortedAccList = sort(accountsToSort).desc(sortFn)
  } else {
    sortedAccList = sort(accountsToSort).asc(sortFn)
  }

  return sortedAccList
}

export const getFilteredAccounts = ({
  accountList,
  accountsSearchText = "",
  sortByColumn,
  sortOrder,
  followingAccounts = [],
  planFilter,
  statusFilter = "active"
}: {
  accountList: any[]
  accountsSearchText: string
  sortByColumn: string
  sortOrder: string
  followingAccounts: number[] | undefined
  planFilter?: string[]
  statusFilter?: string | null
}) => {
  const searchTerm = accountsSearchText.toLowerCase().trim()
  const matchingAccounts: any[] = []

  let filteredAccList: any[] = accountList

  //Pipe functions to get final result. if filters are applied.

  if (planFilter && planFilter?.length > 0) {
    filteredAccList = filterByPlans(filteredAccList, planFilter)
  }

  if (statusFilter) {
    if (statusFilter === CUST_STATUS_FILTER_VALUES.ACTIVE) {
      filteredAccList = getActiveAccounts(filteredAccList)
    } else if (statusFilter === CUST_STATUS_FILTER_VALUES.DISABLED) {
      filteredAccList = getInactiveAccounts(filteredAccList)
    } else if (statusFilter === CUST_STATUS_FILTER_VALUES.FOLLOWING) {
      filteredAccList = getCustomersIFollow(filteredAccList, followingAccounts)
    }
  }

  if (searchTerm.length > 0) {
    filteredAccList.forEach(accountInfo => {
      const { company = "", users = [] } = accountInfo

      const teamNameMatch = company.toLowerCase().includes(searchTerm)

      const matchingUsers = users.filter(userSearchComparator(searchTerm))

      let userListToShow = []
      if (teamNameMatch) {
        userListToShow = users
      } else if (matchingUsers.length > 0) {
        userListToShow = matchingUsers
      }

      //if there are Account name matching. or matching users with name or email
      if (teamNameMatch || matchingUsers.length > 0) {
        matchingAccounts.push({
          ...accountInfo,
          users: userListToShow
        })
      }
    })
    filteredAccList = matchingAccounts
  }

  filteredAccList = getSortedAccountList(
    filteredAccList,
    sortByColumn,
    sortOrder
  )

  return filteredAccList
}

export const exportMeetingsToCsv = (meetings: MeetingInfo[]) => {
  const opts = {
    fields: [
      "account_id",
      "org_name",
      "issue_number",
      "start_time",
      "end_time",
      "meeting_link",
      "engineers_count",
      "is_manual"
    ]
  }

  try {
    const jsonToCsvParser = new Parser(opts)
    const csvContents = jsonToCsvParser.parse(meetings)

    const exportedFileName = `SUBNET_meetings_${format(
      new Date(),
      "dd_MMM_yyyy_hh_mm"
    )}.csv`

    const csvContentsAsBlob = new Blob([csvContents], {
      type: "text/csv;charset=utf-8;"
    })
    saveBlob(csvContentsAsBlob, exportedFileName)
  } catch (err) {
    console.error(err)
  }
}

export const exportIssuesToCsv = (issues: IssueDetails[]) => {
  const opts = {
    fields: [
      { label: "account_id", value: "account_id" },
      { label: "org_name", value: "company" },
      { label: "account_type", value: "account_type" },
      { label: "plan", value: "service_type" },
      { label: "contract_capacity", value: "contract_capacity" },
      { label: "reported_capacity", value: "reported_capacity" },
      { label: "issue_number", value: "issue_number" },
      {
        label: "issue_title",
        value: (row: any, field: any) => {
          const val = row["issue_title"]
          return `"${val}"`
        }
      },
      { label: "is_panic", value: "is_panic" },
      { label: "created_at", value: "created_at" },
      { label: "last_activity_at", value: "last_activity_at" },
      { label: "is_manual", value: "is_manual" }
    ]
  }

  try {
    const jsonToCsvParser = new Parser(opts)
    const csvContents = jsonToCsvParser.parse(issues)

    const exportedFileName = `SUBNET_issues_${format(
      new Date(),
      "dd_MMM_yyyy_hh_mm"
    )}.csv`

    const csvContentsAsBlob = new Blob([csvContents], {
      type: "text/csv;charset=utf-8;"
    })
    saveBlob(csvContentsAsBlob, exportedFileName)
  } catch (err) {
    console.error(err)
  }
}

export const exportAccountsToCsv = (accounts: any) => {
  const opts = {
    fields: [
      { label: "account_id", value: "account_id" },
      { label: "company", value: "company" },
      { label: "account_type", value: "account_type" },
      { label: "service_type", value: "service_type" },
      { label: "is_trial", value: "is_trial" },
      { label: "sla_hours", value: "sla_hours" },
      { label: "start_date", value: "start_date" },
      { label: "end_date", value: "end_date" },
      { label: "reported_capacity", value: "reported_capacity" },
      { label: "contract_capacity", value: "contract_capacity" },
      { label: "last_reported_at", value: "last_reported_at" },
      { label: "account_status", value: "account_status" },
      { label: "subscription_status", value: "subscription_status" },
      { label: "no_of_users", value: "no_of_users" },
      { label: "last_issue_activity_at", value: "last_issue_activity_at" },
      {
        label: "days_since_last_activity",
        value: (row: any, field: any) => {
          const val = row["last_issue_activity_at"]
          if (val) {
            return differenceInDays(new Date(), new Date(val))
          }
        }
      },
      { label: "no_of_issues", value: "no_of_issues" },
      { label: "no_of_clusters", value: "no_of_clusters" },
      { label: "use_cases", value: "use_cases" },
      { label: "account_managers", value: "account_managers" },
      { label: "field_architects", value: "field_architects" },
      { label: "customer_engineers", value: "customer_engineers" }
    ]
  }
  try {
    const jsonToCsvParser = new Parser(opts)
    const csvContents = jsonToCsvParser.parse(accounts)

    const exportedFileName = `SUBNET_accounts_${format(
      new Date(),
      "dd_MMM_yyyy_hh_mm"
    )}.csv`

    const csvContentsAsBlob = new Blob([csvContents], {
      type: "text/csv;charset=utf-8;"
    })
    saveBlob(csvContentsAsBlob, exportedFileName)
  } catch (err) {
    console.error(err)
  }
}

export const exportClustersToCsv = (clusters: any) => {
  const opts = {
    fields: [
      { label: "customer_name", value: "customer_name" },
      { label: "cluster_name", value: "cluster_name" },
      { label: "cluster_capacity", value: "cluster_capacity" },
      { label: "last_update_date", value: "last_update_date" },
      { label: "last_health_upload_date", value: "last_health_upload_date" },
      { label: "reports_since_7_days", value: "reports_since7_days" }
    ]
  }
  try {
    const jsonToCsvParser = new Parser(opts)
    const csvContents = jsonToCsvParser.parse(clusters)

    const exportedFileName = `SUBNET_clusters_${format(
      new Date(),
      "dd_MMM_yyyy_hh_mm"
    )}.csv`

    const csvContentsAsBlob = new Blob([csvContents], {
      type: "text/csv;charset=utf-8;"
    })
    saveBlob(csvContentsAsBlob, exportedFileName)
  } catch (err) {
    console.error(err)
  }
}

export const exportIssueResponseTimeToCsv = (orgName: string, list: any) => {
  const opts = {
    fields: [
      { label: "issue_number", value: "issue_number" },
      { label: "issue_title", value: "issue_title" },
      { label: "created_at", value: "created_at" },
      { label: "created_by", value: "created_by" },
      { label: "response_at", value: "response_at" },
      {
        label: "minutes",
        value: (row: any, field: any) => {
          const createdAt = row["created_at"]
          const createdBy = row["created_by"]
          const responseAt = row["response_at"]
          if (createdBy === "customer" && createdAt && responseAt) {
            return differenceInMinutes(
              new Date(responseAt),
              new Date(createdAt)
            )
          } else {
            return "NA"
          }
        }
      }
    ]
  }
  try {
    const jsonToCsvParser = new Parser(opts)
    const csvContents = jsonToCsvParser.parse(list)

    const exportedFileName = `SUBNET_${orgName}_issue_response_${format(
      new Date(),
      "dd_MMM_yyyy_hh_mm"
    )}.csv`

    const csvContentsAsBlob = new Blob([csvContents], {
      type: "text/csv;charset=utf-8;"
    })
    saveBlob(csvContentsAsBlob, exportedFileName)
  } catch (err) {
    console.error(err)
  }
}

export const MODAL_TYPES = {
  NEW_ORGANIZATION: "NEW_ORGANIZATION",
  UPLOAD_FILE: "UPLOAD_FILE",
  UPGRADE_SUBSCRIPTION: "UPGRADE_SUBSCRIPTION",
  END_SUBSCRIPTION: "END_SUBSCRIPTION",
  PURGE_ACCOUNT: "PURGE_ACCOUNT",
  EDIT_ORG_DETAILS: "EDIT_ORG_DETAILS",
  INVITE_USER: "INVITE_USER",
  RE_ACTIVATE_SUBSCRIPTION: "RE_ACTIVATE_SUBSCRIPTION",
  SEND_NOTIFICATION: "SEND_NOTIFICATION",
  MAKE_ADMIN: "MAKE_ADMIN",
  DISABLE_USER: "DISABLE_USER",
  DISABLE_ORG: "DISABLE_ORG",
  TRIAL_LICENSE: "TRIAL_LICENSE",
  ENABLE_ORG: "ENABLE_ORG",
  EDIT_ORG_NAME: "EDIT_ORG_NAME",
  DISABLE_MFA: "DISABLE_MFA",
  MFA_MODAL: "MFA_MODAL",
  EDIT_NOTES_LINK_MODAL: "EDIT_NOTES_LINK_MODAL",
  GENERATE_LICENSE: "GENERATE_LICENSE",
  SUBSCRIPTION_EVENTS: "SUBSCRIPTION_EVENTS",
  ALERTS: "ALERTS",
  TIME_LOG: "TIME_LOG",
  ACC_MGMT_INFO: "ACC_MGMT_INFO",
  MANAGE_USE_CASES: "MANAGE_USE_CASES"
}

export const CUST_STATUS_FILTER_VALUES = {
  ACTIVE: "active",
  DISABLED: "disabled",
  FOLLOWING: "following"
}
