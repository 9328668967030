import React from "react"
import {
  FieldErrors,
  Control,
  UseFormRegister,
  UseFormTrigger,
  FieldNamesMarkedBoolean
} from "react-hook-form"
import classNames from "classnames"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { ALERT_DANGER } from "../constants"
import CapacityValInput from "../components/CapacityValInput"
import { getFormattedDate } from "../utils"

export type SubscriptionFormValues = {
  planType: string
  company: string
  email?: string
  capacityVal: number | ""
  capacityUnit: string
  startDate: string
  endDate: string | null
  version?: number
}

export type Product = {
  active: boolean
  id: number
  max_quantity: number
  min_quantity: number
  name: string
  quantity_unit: string
  service_type_id: number
  service_type_name: string
  sla_coverage: string
  sla_hours: number
  version: number
}

export const getCustomSubscriptionEndAlert = () => {
  const alertConfig = {
    type: ALERT_DANGER,
    messageComponent: (
      <div className="text-xs md:text-md md:font-medium">
        Your subscription is no longer active. Please renew the subscription as
        soon as possible to restore your support SLAs and to reinstate your
        commercial license. <br />
        All lapsed subscriptions must adhere to the terms of the{" "}
        <a
          className="text-white underline hover:text-white "
          href="https://www.gnu.org/licenses/agpl-3.0.en.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          GNU AGPL v3 license
        </a>
        .
      </div>
    )
  }
  return alertConfig
}

export const getAsDateInput = ({
  fieldName,
  requiredMsg = "",
  fieldLabel,
  validatorFn,
  onChange,
  defaultValue,
  errors,
  register,
  trigger,
  touchedFields,
  maxDate = null,
  minDate = null,
  required = true
}: {
  fieldName: keyof SubscriptionFormValues
  requiredMsg?: string
  fieldLabel: string
  validatorFn: (value: string) => Promise<boolean | string | undefined>
  onChange: (selectedDate: Date | null) => void
  defaultValue: Date | null
  errors: FieldErrors<SubscriptionFormValues>
  register: UseFormRegister<SubscriptionFormValues>
  trigger: UseFormTrigger<SubscriptionFormValues>
  touchedFields: Partial<
    Readonly<FieldNamesMarkedBoolean<SubscriptionFormValues>>
  >
  minDate?: Date | null
  maxDate?: Date | null
  required?: boolean
}) => {
  const errorConfig: any = errors[fieldName]

  let validationConfig: any = {
    validate: validatorFn
  }

  if (required) {
    validationConfig = {
      ...validationConfig,
      required: requiredMsg
    }
  }

  return (
    <div className="mb-6 mt-6">
      <label htmlFor="name" className="mb-2 block font-medium text-black">
        {fieldLabel}
      </label>

      <input
        type="text"
        id={fieldName}
        defaultValue={getFormattedDate(defaultValue)}
        {...register(fieldName, {
          ...validationConfig
        })}
        style={{ display: "none" }} //important since we fake date field for validation
      />

      <ReactDatePicker
        calendarClassName="date-picker"
        dateFormat="dd-MM-yyyy"
        showPopperArrow={false}
        showMonthDropdown
        showYearDropdown
        selected={defaultValue}
        placeholderText="DD-MM-YYYY"
        todayButton="Today"
        maxDate={maxDate}
        minDate={minDate}
        customInput={
          <input
            type="text"
            placeholder={"YYYY-MM-DD ex: 2020-11-20"}
            className={classNames({
              "form-field ring-gray-100 focus-visible:ring-4": true,
              invalid: errorConfig && errorConfig.message,
              valid:
                touchedFields[fieldName] && errorConfig && !errorConfig.message
            })}
          />
        }
        onChange={onChange}
        onBlur={() => {
          trigger(fieldName)
        }}
      />
      {errorConfig && (
        <div className="mt-2 text-sm font-medium text-error">
          {errorConfig.message}
        </div>
      )}
    </div>
  )
}

export const getCapacityInput = (
  control: Control<SubscriptionFormValues, any>,
  register: UseFormRegister<SubscriptionFormValues>
) => {
  const capacityInputBaseCss =
    "text-black border-0 appearance-none bg-transparent text-center w-full h-full pr-16"
  const capacityUnitInputBaseCss =
    "flex-1 text-center appearance-none bg-transparent text-sm w-16 absolute right-0 top-0 border-l rounded-none border-gray-200 bottom-0 my-auto h-6 text-black"
  return (
    <React.Fragment>
      <CapacityValInput
        name="capacityVal"
        control={control}
        inputCls={capacityInputBaseCss}
      />
      <input
        type="text"
        id="capacityUnit"
        className={classNames({
          [capacityUnitInputBaseCss]: true
        })}
        readOnly
        value="TiB"
        {...register("capacityUnit", { required: true })}
      />
    </React.Fragment>
  )
}

export const getCapacityField = (
  title: string,
  control: Control<SubscriptionFormValues, any>,
  register: UseFormRegister<SubscriptionFormValues>,
  errors: FieldErrors<SubscriptionFormValues>
) => {
  return (
    <div className="mb-3 sm:flex">
      <div className="sm:w-7/12">
        <div className="mb-2 font-medium text-black">{title}</div>
        <div className="text-sm">Amount of data stored on MinIO</div>
      </div>
      <div className="mt-5 sm:mt-0 sm:w-5/12">
        <div className="md:w-full ">
          <div
            className={classNames({
              "form-field": true,
              invalid: errors.capacityVal
            })}
          >
            {getCapacityInput(control, register)}
          </div>
          <div className="mt-2 text-center text-sm">
            {errors.capacityVal && (
              <div className="font-medium text-error">
                {errors.capacityVal.message}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export const getTeamNameField = (company: string) => {
  return (
    <div className="mb-6">
      <label htmlFor="company" className="mb-2 block font-medium text-black">
        Organization Name
      </label>
      <input
        id="company"
        value={company}
        type="text"
        className="form-field"
        disabled
      />
    </div>
  )
}

export const ENG_ACC_ROLES: Record<string, string> = {
  ACCOUNT_MANAGER: "Account Manager",
  FIELD_ARCHITECT: "Field Architect",
  CUSTOMER_ENGINEER: "Customer Engineer"
}

export type EngAccountRole = {
  role: string
  role_id: number
  role_user_ids: string // , separated
  role_user_names: string //, separated
  role_user_emails: string //, separated
  role_user_uids: string //, separated
}
