import { clearAlertByCode, setAlert } from "./actions"
import { ALERT_DANGER } from "./constants"
import { store } from "./configure-store"

export const ERROR_APP_ALERT_CODE = 99999

/*
 * This is a utility to show error alert even from a non connected component since the alert state is part of redux.
 * If the error/exception needs to be handled contextually and propagated, use a callback like:
 * (error)=>{
 *     //... do the pre handling in context
 *    //handleErrorWithAlert(error)
 * }
 */
const handleErrorWithAlert = error => {
  //Clear to avoid too many alerts of this type.
  store.dispatch(clearAlertByCode(ERROR_APP_ALERT_CODE))
  //if error is thrown with new Error("<message>") within the code block context, then use message.
  //If it is a http response error, it will always be error.error

  const msgToShow = error.message ? error.message : error.error
  store.dispatch(
    setAlert({
      type: ALERT_DANGER,
      errorCode: ERROR_APP_ALERT_CODE,
      message: `An Error occurred : ${msgToShow}`
    })
  )
}

export default handleErrorWithAlert
